 <template>
  <div>
    <div>
      <navbar />
    </div>
    <div>
      <div v-if="this.designResponse == 'success'" class="alert alert-success" role="alert">
        Update Succesfully
      </div>
      <div v-if="this.designResponse == 'error'" class="alert alert-danger" role="alert">
        Error Occured
      </div>
    </div>
      <ul class="nav nav-tabs center">
        <li class="nav-item">
          <a class="nav-link active" id="createUser" @click="changePage('createUser')">Create User</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="designPage" @click="changePage('designPage')">Exclusive Design</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="searchAccount" @click="changePage('searchAccount')">Search Account</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="accountInfo" @click="changePage('accountInfo')">Account Info</a>
        </li>
      </ul>
      <div v-if="this.currentPage == 'createUser'"  class ="center">
       <h3 class="title mt-3" style="text-align:center">Create User</h3>
         <form class="center row g-3" @submit.prevent="createAccount">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" class="form-control" id="email" placeholder="Enter email" v-model.trim="email">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="text" class="form-control" id="password" placeholder="Enter password" v-model.trim="password">
                </div>
              </div>
            </div>
            <div class="col-md-6"  v-if="this.addUserStatus=='none'">
              <div>
                <button type="submit" class="btn btn-primary" >Submit</button>
              </div>
            </div>
            <div class="col-md-6"  v-if="this.addUserStatus=='updating'">
              <div>
                <button type="submit" class="btn btn-primary" disabled>
                <i class="fa fa-circle-o-notch fa-spin"></i>  Creating
              </button>
              </div>
            </div>

          </form>  
        <div v-if="this.response== 'success'" class="center row g-3">
          <h3 class="title mt-3" style="text-align:center" >User Info </h3>
          <div class="row">
            <div class="col-md-4"><h5>Email:<br/> {{ this.defaultEmail }}</h5></div>
            <div class="col-md-4"><h5>Default Password: <br/>{{ this.defaultPassword }}</h5></div>
            <div class="col-md-4"><h5>URL: <br/>{{ this.userURL }}</h5></div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-4">
                <h5>Background Color</h5>
                  <VSwatches 
                  v-model="backgroundColor" 
                  show-fallback
                  fallback-input-type="color">
                  </VSwatches>
              </div>
              <div class="col-md-4">
                <h5>Foreground Color</h5>
                  <VSwatches 
                  v-model="foregroundColor" 
                  show-fallback
                  fallback-input-type="color">
                  </VSwatches>
              </div>
            </div>
          </div>
          <div>
             <!-- <canvas id="qrcode"></canvas> -->
             <canvas ref="qrcode"></canvas>
          </div>
          <div>
              <button class="btn btn-primary" @click="downloadQRCode" style="margin-right:10px;">Download QR code</button>
              <button class="btn btn-primary" @click="regenQR">Create QR code</button>

          </div>

        </div>
        <div v-if="this.response== 'error'" class="center row g-3">
          <h3 class="title mt-3 text-danger" style="text-align:center" >Error </h3>
          <div class="row">
            <div class="col-md-12 text-danger"><h5>Error: User might exist, if problem persist contact support</h5></div>
          </div>
        </div>
               <div class ="row mb-3">
          <div v-if="this.codeError=='error'">
            <h3 class="title mt-3" style="text-align:center" >Error Getting Codes Seek Help!!!!!!!</h3>
          </div>
          <div v-if="this.codeError=='error2'">
            <h3 class="title mt-3" style="text-align:center" >Code Never been use</h3>
          </div>
          <table class="table">
            <thead>
              <tr>  
                <th>Code</th>
                <th>Used</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in allCodes" :key="index">
                <td>{{ item.code }}</td>
                <td> 
                    <button class="btn btn-primary" @click="setCode(item.code,index)" style="width:100px">USE IT</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
    </div>
    <div v-if="this.currentPage == 'AccountInfo'">
       <h3 class="title mt-3" style="text-align:center">Account Info </h3>
        <form @submit.prevent="resetPassword" class ="center row g-3">
          <div class="col-12">
              <label for="formFile" class="form-label"><h5>Reset Password</h5></label>
          </div>
          <div class="col-12">
            <button type="submit" class="btn btn-primary">Reset Password</button>
          </div>
        </form>
      </div>
    <div v-if="this.currentPage == 'designPage'" class ="center">
      <h3 class="title mt-3" style="text-align:center">Add User to Exclusive Design</h3>
        <div>
          <div>
            <div v-for="(email, index) in allEmails" :key="index" class="badge badge-pill badge-primary">
              <button class="btn btn-success mb-2" style="width: auto !important; color:white;" @click="removeEmail(index)">
                {{ email }}   <i class="fa fa-times"></i>
              </button>
            </div>
            <div class ="row mb-3">
                <div class="col-10">
                  <input type="email" v-model="newEmail" class="form-control" placeholder="Add email">
                </div>
                <div class="col-2">
                  <button class="btn btn-primary" @click="addEmail()">Add User</button>
                </div>
            </div>
            <div class ="row mb-4">
              <div>
                <!-- <label for="select-option">Select a Design:</label> -->
                <select id="select-option" class="form-control" v-model="selectedOption">
                  <option>Select A Design</option>
                  <option v-for="(value, key, index) in designInfos" :key="index" :value="value.designID">{{ value.name }}</option>
                </select>
              </div>
            </div>
            <div class ="row">
               <div v-if="this.addDesignStatus=='none'">
                <button class="btn btn-primary" @click="addUserDesign()"> Submit </button>
              </div>
              <div  v-if="this.addDesignStatus=='updating'">
                <button type="submit" class="btn btn-primary" disabled>
                  <i class="fa fa-circle-o-notch fa-spin"></i>  Updating
                </button>
              </div>

            </div>
            
          </div>
        </div>
        
    </div>
    <div v-if="this.currentPage == 'searchAccount'" class ="center">
       <div class ="row mb-3">
            <div class="col-10">
              <input type="email" v-model="searchEmail" class="form-control" placeholder="Search email">
              <p v-if="this.searchError!=''" class="text-danger">Error: Possible that user do not exist</p>
            </div>
            <div class="col-2" v-if="this.searchUserStatus=='none'">
              <button class="btn btn-primary" @click="searchUserInfo()">Search User</button>
            </div>
            <div class="col-2" v-if="this.searchUserStatus=='updating'">
              <button type="submit" class="btn btn-primary" disabled>
                  <i class="fa fa-circle-o-notch fa-spin"></i>  Searching
                </button>
            </div>
        </div>
        <table class="table">
          <thead>
            <tr>  
              <th>ID</th>
              <th>Email</th>
              <th>Name</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in searchUser" :key="index">
              <td>{{ item.uid }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.name }}</td>
              <td> 
                  <button v-if="this.removeUserStatus=='none'" class="btn btn-danger" @click="removeUser(item.uid)" style="width:100px">Delete</button>
                  <button v-if="this.removeUserStatus=='updating'" type="submit" class="btn btn-danger" disabled>
                    <i class="fa fa-circle-o-notch fa-spin"></i>  Deleting
                  </button>   
              </td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import navbar from "../components/navbar.vue";
import { collection, query, where, getDocs} from "firebase/firestore";
import { auth,db } from "../firebaseDB";
import router from "@/router";
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
// import QRCode from 'qrcode';
import { VSwatches } from 'vue3-swatches'
import 'vue3-swatches/dist/style.css'
import QRious from 'qrious';

export default {
  components: { navbar, VSwatches },
  data() {
    return {
      email: "",
      password:"",
      id_token: "",
      apiURL: "https://backend-updateadmin-sobafbawhq-uc.a.run.app",
      currentPage: "createUser",
      // updateStatus: "none",
      defaultEmail:"",
      defaultPassword:"",
      response:"",
      // qrImage:"",
      newUserID:"",
      userURL:"",
      backgroundColor:'',
      foregroundColor:"",
      newEmail: '',
      allEmails:[],
      designInfos :[],
      selectedOption:"",
      designResponse:"",
      searchUser:[],
      searchEmail:"",
      searchError:"",
      addUserStatus:"none",
      addDesignStatus:"none", 
      searchUserStatus:"none",
      removeUserStatus:"none",
      codeError:"",
      allCodes:[]

    };
  },
  async mounted() {
    this.accID = this.$store.getters.getUserId;
    await this.checkAuthState();
    this.password = uuidv4().substring(0,8)
    const q = query(collection(db, "designTemplates"), where("exclusive", "==", true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
   
      var docData = doc.data()
      docData["designID"] = doc.id
      this.designInfos.push( docData)
      
    });
    await this.getProductCode()
  },
  methods: {
    setCode(currentCode, codeIndex){
      console.log(currentCode)
      axios.post(this.apiURL+"/setAdminUsed", { 
          token: this.id_token,
          codeID:currentCode
        })
        .then(() => {
          this.codeError =""
          this.allCodes.splice(codeIndex,1)
        })
        .catch(() => {
         
          this.codeError = "error2";
        });

    },
    getProductCode(){
      axios.get(this.apiURL+"/getCodes",{
        headers: {
            'Authorization': this.id_token,
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
          this.codeError = "";
          this.allCodes = response.data.message;
          console.log(response.data);
        })
        .catch(() => {
         
          this.codeError = "error";
        });
    },
    removeUser(uid) {
      this.removeUserStatus="updating"
      axios
        .delete(this.apiURL + "/remove", {
          data: {
            userID: uid,
            id_token: this.id_token,
          },
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          this.searchUser =[]
            this.searchEmail= ""
          this.designResponse = "success";
          setTimeout(() => {
            this.designResponse = "";
          }, 3000);
          this.removeUserStatus="none"

        })
        .catch(() => {
          this.designResponse = "error";
          this.removeUserStatus="none"

          setTimeout(() => {
            this.designResponse = "";
          }, 3000);
        });
    },
    async searchUserInfo(){
      this.searchUserStatus="updating"

     axios
        .get(this.apiURL + "/getUserData/"+this.searchEmail, {
          headers: {
            'Authorization': this.id_token,
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return;
          }
          this.searchError=""
          this.searchUser =[response.data.message]
           this.searchUserStatus="none"
          
        })
        .catch(() => {
          this.searchError="error"
           this.searchUserStatus="none"

        });
    },
    addUserDesign(){
      this.addDesignStatus = "updating"
      axios.post(this.apiURL + "/exclusive", {
          userEmails: this.allEmails,
          designID: this.selectedOption,
          id_token: this.id_token
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return ;
          }
          this.designResponse ="success"
          this.allEmails=[]
          this.selectedOption=""
          setTimeout(() => {
            this.designResponse = "";
          }, 3000);
          this.addDesignStatus = "none"

        }).catch(()=>{
          this.addDesignStatus = "none"
          this.designResponse = "error"
          setTimeout(() => {
            this.designResponse = "";
          }, 3000);
        })
    },
    regenQR(){
        new QRious({
              element: this.$refs.qrcode,
              value: this.userURL,
              size:400,
              foreground:this.foregroundColor,
              background:this.backgroundColor
            });
    },
     async createAccount(){
      this.addUserStatus = "updating"
      await axios.post(this.apiURL + "/", {
          email: this.email,
          password: this.password,
          id_token: this.id_token
        })
        .then((response) => {
          if (response.status == 401) {
            alert("Login Expire! Please Login Again");
            router.push("/");
            return ;
          }
          this.response = "success"
          this.defaultEmail = this.email;
          this.defaultPassword = this.password
          this.email = ""
          this.password= uuidv4().substring(0,8)
          this.userURL= response.data.message
          this.$nextTick(() => {
          new QRious({
              element: this.$refs.qrcode,
              value: response.data.message,
              size:400
            });
        })
      this.addUserStatus="none"

        })
        .catch(() => {
          this.response = "error"
          this.addUserStatus = "none"

          // Handle error
          return "error"
        });
    },
    downloadQRCode() {
      const canvas = this.$refs.qrcode;
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('download', 'qrcode.png');
      canvas.toBlob(function(blob) {
        const url = URL.createObjectURL(blob);
        downloadLink.setAttribute('href', url);
        downloadLink.click();
      });
    },
    async checkAuthState() {
      const auth = getAuth();
      await onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            this.id_token = idToken;
          });
        } else {
          router.push("/");
        }
      });
    },
    addEmail() {
      if (this.newEmail !== '' && this.validateEmail(this.newEmail)) {
        this.allEmails.push(this.newEmail);
        this.newEmail = '';
      }
    },
      removeEmail(index) {
        this.allEmails.splice(index, 1);
      },
      validateEmail(email) {
        // A simple email validation function, replace it with a more comprehensive one if needed.
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      },
    resetPassword() {
      // const auth = getAuth();
      sendPasswordResetEmail(auth, this.$store.getters.getCurrentEmail)
        .then(() => {
          alert("Email Sent! Please check you junk/spam folder");
        })
        .catch(() => {});
    },
    changePage(changeVal){
        if (changeVal=="createUser"){
          this.currentPage ="createUser"
          document.getElementById("createUser").className ="nav-link active"
          document.getElementById("designPage").className ="nav-link"
          document.getElementById("accountInfo").className ="nav-link"
          document.getElementById("searchAccount").className ="nav-link"


        }
        else if(changeVal=="accountInfo"){
          this.currentPage ="AccountInfo"
          document.getElementById("createUser").className ="nav-link"
          document.getElementById("designPage").className ="nav-link"
          document.getElementById("accountInfo").className ="nav-link active"
          document.getElementById("searchAccount").className ="nav-link"

        }
        else if(changeVal=="designPage"){
          this.currentPage ="designPage"
          document.getElementById("createUser").className ="nav-link"
          document.getElementById("designPage").className ="nav-link active"
          document.getElementById("accountInfo").className ="nav-link"
          document.getElementById("searchAccount").className ="nav-link"

        }
        else if(changeVal=="searchAccount"){
          this.currentPage ="searchAccount"

          document.getElementById("createUser").className ="nav-link"
          document.getElementById("designPage").className ="nav-link"
          document.getElementById("accountInfo").className ="nav-link"
          document.getElementById("searchAccount").className ="nav-link active"


        }
    },
    
  },
};
</script>

<style scoped>
body {
  background-color: white !important;
  font-family: "Montserrat",  sans-serif;
}
button{
  width: 200px;  
}
.alert {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  border-radius: 0px;
}
.center {
  margin: auto;
  width: 80%;
  padding: 10px;
}
a {
  text-decoration: none !important;
}

@media (max-width:912px ) {
  .wholeCard{
    width:160%
  }
}

@media (max-width:280px) {
  .wholeCard{
    width:200%
  }
}


@media (max-width: 767px) {
  .col-4 {
    flex-basis: 100%;
  }

  .card-img-left {
    width: 100%;
    height: auto;
  }
  .card{
    margin-bottom: 20px;
  }
}
</style>